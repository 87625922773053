
export const cardData = [
    {
      heading: 'Passion',
      desc: 'Bringing our love into work every day.'
    },
    {
      heading: 'Ownership',
      desc: 'Owning our mistakes allows us to grow and learn.'
    },
    {
      heading: 'Trust',
      desc: 'Building faith one project at a time.'
    },
  ]

export const missionStatement = {
  
}